import React from 'react';
// import { useCallback } from "react";
// import Particles from "react-particles";
// import { loadFull } from "tsparticles"; 

import logo from './logo.png';
import './App.css';

function App() {
  // const particlesInit = useCallback(async engine => {
  //   console.log(engine);
  //   // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
  //   // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
  //   // starting from v2 you can add only the features you need reducing the bundle size
  //   await loadFull(engine);
  // }, []);

//   const particlesLoaded = useCallback(async container => {
//     await console.log(container);
// }, []);

  return (
    <div className="App">
      {/* <Particles id="tsparticles" url="http://foo.bar/particles.json" init={particlesInit} loaded={particlesLoaded} /> */}
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          this is the beginning of lovemaking
        </p>
        <p>
          for body.mind.soul.
        </p>
      </header>
      <p>
        p.s. Rugilytė Šaunuolytė
      </p>
    </div>
  );
}

export default App;
